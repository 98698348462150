import '../css/app.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import KeenjaProvider from './lib/components/keenja-provider';

const pages = import.meta.glob('./pages/**/*.tsx');

function resolvePageComponent(name: string) {
  for (const path in pages) {
    const fileName = path.replace('./pages/', '');
    if (fileName === `${name.replace('.', '/')}.tsx`) {
      return typeof pages[path] === 'function' ? pages[path]() : pages[path];
    }
  }

  throw new Error(`Page not found: ${name}`);
}

createInertiaApp({
  resolve: name => resolvePageComponent(name),
  setup({ el, App, props }) {
    createRoot(el).render(
      <KeenjaProvider>
        <App {...props} />
      </KeenjaProvider>,
    );
  },
  progress: {
    color: '#29d',
  },
});
