import { createContext } from 'react';
import defaultTheme, { KeenjaTheme } from './default-theme';
import { RecursivePartial } from './types';
import deepMerge from './util/deep-merge';

export function extendTheme<T extends KeenjaTheme>(
  baseTheme: T,
  customTheme: RecursivePartial<T>,
): KeenjaTheme {
  return deepMerge(baseTheme, customTheme);
}

export const ThemeProvider = createContext({ theme: defaultTheme });
