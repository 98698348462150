import React from 'react';
import { ThemeProvider } from '../theme';
import { PropsWithChildren } from 'react';
import defaultTheme, { KeenjaTheme } from '../default-theme';

interface Props {
  theme?: KeenjaTheme;
}

export default function KeenjaProvider({
  children,
  theme,
}: PropsWithChildren<Props>) {
  return (
    <ThemeProvider.Provider value={{ theme: theme || defaultTheme }}>
      {children}
    </ThemeProvider.Provider>
  );
}
